@import "@blueprintjs/core/lib/scss/variables";

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: $pt-navbar-height;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  overflow-x: auto;
  .performanceWrapper {
    margin-top: 100px;
    .chartWrapper { 
      position: relative;
      width: 100%;
      height: 75vh;
      overflow: hidden;
    }
    .cardHeader {
      border-bottom: 1px solid $light-gray3;
      padding-bottom: 10px;
      margin-bottom: 35px;
    }
  }
}

.emptyStateWrapper {
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  .icon {
    color: $light-gray1;
  }
  h1{
    font-size: 20px;
    color: $dark-gray5;
    margin-bottom: 15px;
  }
  p {
    color: $dark-gray5;
    font-size: 16px;
  }
}

.spinnerWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}