@import "@blueprintjs/core/lib/scss/variables";
@import "../variables";

.platformHeader {
  position: relative;
  top: 0px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;  
}

.stickyPlatformHeader {
  position: sticky;
  top: 75px;
  background-color: $white;
  z-index: 1;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;  
}

.platformItem {
  float: left;
  width: $gridCellWidth;
  height: 60px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-left-style: solid;
  border-image: linear-gradient(to top, #A7B6C2, rgba(0, 0, 0, 0)) 1 100%;
  position: relative;
  &.emptyItem {
    width: 250px;
  }
}

.withFlag {
  border-image: none;
  border-color: #A7B6C2;
}

.platformLogo {
  width: 60px;
  height: 60px;
  background-size: cover;
}

.buyHeader {
  width: 50%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 25px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rentHeader {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  height: 25px;

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buyHeader div, .rentHeader div {
  font-size: 10px;
  border-radius: 4px;
  border-color: #8A9BA8;
  border-width: 2px;
  padding-left: 3px;
  padding-right: 3px;
  text-transform: uppercase;
  font-weight: bold;
  color: #8A9BA8;
}

.flag {
  width: 20px;
  height: 47px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  left: 10px;
  top: 10px;
  opacity: 0.8;
}

.platformCog {
  width: 20px;
  height: 47px;
  position: absolute;
  right: 10%;
  top: 10%;
  cursor: pointer;
}

.spinCog {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear;
}

@-webkit-keyframes spin {

  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {

  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
