@import "@blueprintjs/core/lib/scss/variables";
@import "./variables.scss";

.header {
  width: 100%;
  height: 75px !important;
  background-color: rgb(103, 0, 103) !important;
  position: fixed !important;
  top: 75px;
  .toolbarGroup {
    height: 75px !important;
    .tagWrapper {
      width: 400px;
      .tag {
        background-color: $lightBlue;
      }
    }
    .customPopover {
      max-height: 200px; 
      overflow-y: auto; 
    }
    .selectWidth {
      button {
        min-width: 150px;
        text-align: left;
      }
    }
  }
  .divider {
    margin: 0 10px;
    border-left: 1px solid rgba(255, 255, 255, 0.15);
    height: 45px;
  }
}
.merchPtMenu {
  overflow-y: auto;
  max-height: 600px;
  padding: 1%;
  width: 235px;
  div {
    background: $white;
    padding: 10px 5px;
    border-bottom: 1px solid rgb(223, 223, 223);
    span{
      cursor: pointer;
      display: flex;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  ul {
    margin-left: 7px;
    padding: 1%;
    li {
      margin: 7px 0;
    }
  }
  .chevronIcon {
    margin-left: auto;
    margin-right: 5px;
  }
  .platformName {
    margin-left: 10px;
  }
}

.metricMenu {
  li {
    padding: 5px 2px;
    cursor: pointer;
    &:hover {
      background-color: $lightBlue;
    }
  }
}

.btnDefault {
  box-shadow: inset 0 0 0 1px rgba(223, 238, 241, 0.2), inset 0 1px 2px rgba(223, 238, 241, 0.2) !important;
  background-color: $white !important;
  border: 1px solid $lightBlue !important;
  min-width: 150px !important;
  &.active, &:hover {
    background-color: $lightBlue !important;
  }
  .caretIcon {
    float: right;
  }
}

@media (max-width: 1125px) {
  .header {
    height: 150px !important;
  }

  .toolbarGroup {
    flex-wrap: wrap;
  }
}
