.gridContainer {
  height: calc(100vh - 210px);
}

.spinnerWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}